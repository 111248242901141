$(function () {
  'use strict';

  $(document).ready(function () {
    var $buttons = $('.js-carbon-button');
    var $overlay = $('.js-carbon-overlay');
    var $closeButtons = $('.js-carbon-close');
    var emissionResult = '';
    var diagramResult = '';
    var diagramTotalResult = '';

    function init () {
      var $carbonButtonContainer = $('.js-carbon-button-container');
      var observer = new IntersectionObserver(
        function (entries) {
          entries.forEach(function (entry) {
            if (entry.isIntersecting) {
              $carbonButtonContainer.addClass('hide');
              console;
            } else {
              $carbonButtonContainer.removeClass('hide');
            }
          });
        },
        {
          root: null,
          threshold: 0.1,
        }
      );

      var $carbonElement = document.querySelector('.js-carbon-footer');
      if ($carbonElement) {
        observer.observe($carbonElement);
      }

      $buttons.on('click', function () {
        if ($overlay.length) {
          $overlay.removeClass('none').addClass('open');
          $('html, body').animate({ scrollTop: 0 }, 'fast');
          $('html').addClass('carbon-simulator-opened');
          listenToFormChanges($overlay);
          initTooltip($overlay);
        }
      });

      $closeButtons.on('click', function () {
        if (!$overlay.hasClass('none')) {
          $overlay.addClass('none').removeClass('open');
        }
        $('html').removeClass('carbon-simulator-opened');
      });

      function listenToFormChanges($overlay) {
        var $form = $overlay.find('.js-carbon-form');
        var $resultContainer1 = $overlay.find('.js-carbon-result-1');
        var $resultContainer2 = $overlay.find('.js-carbon-result-2');
        var $resultContainer3 = $overlay.find('.js-carbon-result-3');
        var $carResultContainer = $overlay.find('.js-carbon-car');
        var $streamingResultContainer = $overlay.find('.js-carbon-streaming');
        var $residentSelect = $form.find('.js-carbon-resident');

        function updateResidentOptions(selectedType) {
          var $options = $residentSelect.find('option');

          $options.prop('disabled', false);

          if (selectedType === 'gamme-roseaux' || selectedType === 'gamme-iris') {
            $options.each(function () {
              var value = $(this).val();
              if (['11', '13', '15', '17', '19'].includes(value)) {
                $(this).prop('disabled', true);
              }
            });
          } else if (selectedType === 'gamme-carex') {
            $options.each(function () {
              var value = $(this).val();
              if (value !== '5' && value !== '6') {
                $(this).prop('disabled', true);
              }
            });
          }

          var selectedValue = $residentSelect.val();
          if ($residentSelect.find('option:selected').prop('disabled')) {
            var firstValidOption = $residentSelect.find('option:not(:disabled)').first();
            if (firstValidOption.length > 0) {
              $residentSelect.val(firstValidOption.val());
            }
          }
        }

        if ($form.length > 0) {
          $form.find('input[name="jardin-gamme"], .js-carbon-resident, .js-carbon-years').on('change input', function () {
            var selectedType = $form.find('input[name="jardin-gamme"]:checked').val();
            var selectedResident = parseFloat($form.find('.js-carbon-resident').val());
            var carResult = '';
            var streamingResult = '';
            diagramResult = '';
            diagramTotalResult = ''
            var coffeeResult = '';

            updateResidentOptions(selectedType);

            if (!isNaN(selectedResident)) {
              var totalResult;

              switch (selectedType) {
                case 'gamme-roseaux':
                  var roseauxResults = {
                    2: 10476,
                    3: 15715,
                    4: 20953,
                    5: 26191,
                    6: 31429,
                    7: 36667,
                    8: 41906,
                    9: 47144,
                    10: 52382,
                    12: 62858,
                    14: 73335,
                    16: 83811,
                    18: 94288,
                    20: 104764,
                  };
                  totalResult = roseauxResults[selectedResident];

                  var roseauxCarResults = {
                    2: 45791,
                    3: 68687,
                    4: 91583,
                    5: 114478,
                    6: 137374,
                    7: 160270,
                    8: 183165,
                    9: 206061,
                    10: 228957,
                    12: 274748,
                    14: 320539,
                    16: 366331,
                    18: 412122,
                    20: 457913,
                  };
                  carResult = roseauxCarResults[selectedResident];

                  var roseauxStreamingResults = {
                    2: 58766,
                    3: 88148,
                    4: 117531,
                    5: 146914,
                    6: 176297,
                    7: 205679,
                    8: 235062,
                    9: 264445,
                    10: 293828,
                    12: 352593,
                    14: 411359,
                    16: 470124,
                    18: 528890,
                    20: 587656,
                  };
                  streamingResult = roseauxStreamingResults[selectedResident];

                  var roseauxDiagramResults = {
                    2: -101,
                    3: -152,
                    4: -203,
                    5: -253,
                    6: -304,
                    7: -355,
                    8: -406,
                    9: -456,
                    10: -507,
                    12: -608,
                    14: -710,
                    16: -811,
                    18: -913,
                    20: -1014,
                  };
                  diagramResult = roseauxDiagramResults[selectedResident];
                  break;
                case 'gamme-iris':
                  var irisResults = {
                    2: 10161,
                    3: 15242,
                    4: 20323,
                    5: 25403,
                    6: 30484,
                    7: 35565,
                    8: 40646,
                    9: 45726,
                    10: 50807,
                    12: 60968,
                    14: 71130,
                    16: 81291,
                    18: 91453,
                    20: 101614,
                  };
                  totalResult = irisResults[selectedResident];

                  var irisCarResults = {
                    2: 44867,
                    3: 67300,
                    4: 89733,
                    5: 112167,
                    6: 134600,
                    7: 157033,
                    8: 179467,
                    9: 201900,
                    10: 224333,
                    12: 269200,
                    14: 314067,
                    16: 358933,
                    18: 403800,
                    20: 448667,
                  };
                  carResult = irisCarResults[selectedResident];
                  var irisStreamingResults = {
                    2: 57579,
                    3: 86368,
                    4: 115158,
                    5: 143947,
                    6: 172737,
                    7: 201526,
                    8: 230316,
                    9: 259105,
                    10: 287894,
                    12: 345473,
                    14: 403052,
                    16: 460631,
                    18: 518210,
                    20: 575789,
                  };
                  streamingResult = irisStreamingResults[selectedResident];

                  var irisDiagramResults = {
                    2: -203,
                    3: -304,
                    4: -406,
                    5: -507,
                    6: -608,
                    7: -710,
                    8: -811,
                    9: -913,
                    10: -1014,
                    12: -1217,
                    14: -1420,
                    16: -1622,
                    18: -1825,
                    20: -2028,
                  };
                  diagramResult = irisDiagramResults[selectedResident];
                  break;
                case 'gamme-carex':
                  var carexResults = {
                    5: 28588,
                    6: 34306,
                  };
                  totalResult = carexResults[selectedResident];

                  var carexCarResults = {
                    5: 124306,
                    6: 149168,
                  };
                  carResult = carexCarResults[selectedResident];

                  var carexStreamingResults = {
                    5: 159526,
                    6: 191432,
                  };
                  streamingResult = carexStreamingResults[selectedResident];

                  var carexDiagramResults = {
                    5: -127,
                    6: -152,
                  };

                  diagramResult = carexDiagramResults[selectedResident];
                  break;
                default:
                  return;
              }

              emissionResult = totalResult;

              if (carResult) {
                $carResultContainer.text(carResult.toLocaleString());

              $streamingResultContainer.text(streamingResult.toLocaleString());

              var container1Number = diagramResult * (-1);
              $resultContainer1.text(container1Number.toLocaleString());
              $resultContainer2.text(emissionResult.toLocaleString());
              var container3Number = diagramResult * (-1) + emissionResult;
              $resultContainer3.text(container3Number.toLocaleString());
            }


            } else {
              console.warn('Valeurs du formulaire invalides.');
            }
          });
        }
      }

      function numberToText(number, specialRound) {
        if (specialRound) {
          number = Math.round(number / 500) * 500;
        }
        else {
          number = Math.round(number);
        }
        number = number.toString();

        var x = number.split('.');
        var x1 = x[0];
        var x2 = x.length > 1 ? ',' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ' ' + '$2');
        }
        return x1 + x2;
      }

      function initTooltip($overlay) {
        var $tooltipBtn = $overlay.find('.carbon-tooltip-btn');
        var $tooltip = $overlay.find('.carbon-tooltip');
        var $tooltipClose = $overlay.find('.carbon-tooltip-close');

        $tooltipBtn.on('click', function () {
          $tooltip.toggleClass('none');
        });

        $tooltipClose.on('click', function () {
          $tooltip.addClass('none');
        });
      }
    }

    init();
  });
});
